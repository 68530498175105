/* Pagination styles using Flexbox */
.pagination-container {
    display: flex;
    justify-content: center; 
    align-items: center;
     
  }
  
  .page-item {
    margin: 0 5px;
    display: flex;
  }
  .pagination{

    display: flex;
    justify-content: center; 
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .page-link {
    padding: 8px 12px;
    text-decoration: none;
    color: black;
    background-color: #f2f4f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .page-link:hover {
    background-color: #032850;
  }
  
  .active .page-link {
    background-color: #28a745;
    color: white;
  }
  
  .page-link[aria-disabled="true"] {
    background-color: #6c757d;
    pointer-events: none;
  }
  