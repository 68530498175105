/* body {
     overscroll-behavior: none;
     margin: 0;
     padding: 0;
     overflow-x: hidden;
   } */

   #smooth-content {
     overflow: visible;
     width: 100%;
     /* set a height because the contents are position: absolute, thus natively there's no height */
     height: 4000px;
   
     background-image: linear-gradient(
         rgba(255, 255, 255, 0.07) 2px,
         transparent 2px
       ),
       linear-gradient(90deg, rgba(255, 255, 255, 0.07) 2px, transparent 2px),
       linear-gradient(rgba(255, 255, 255, 0.06) 1px, transparent 1px),
       linear-gradient(90deg, rgba(255, 255, 255, 0.06) 1px, transparent 1px);
     background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
     background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
   }

   #smooth-content {
    overflow: visible;
    width: 100%;
    /* set a height because the contents are position: absolute, thus natively there's no height */
    height: 100vh;
  
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.07) 2px,
        /* transparent 2px */
      ),
      
  }