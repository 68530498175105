.login{
     /* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* background: rgba(22, 147, 189, 0.14); */
background-image: url("../../assets/images/tmc1.jpg");
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.5px);
-webkit-backdrop-filter: blur(6.5px);
border: 1px solid rgba(22, 147, 189, 1);
background-size: cover;
background-repeat: no-repeat;
}